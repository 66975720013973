//hamburger menu
function createHamburgerMenu () {

  var $win = $(window);
  var ww = window.innerWidth;
  var currentWidth = window.innerWidth;
  var breakpoint = 1024;

  // ---  横幅が変動した時に実行
//  $win.on('load', function () {
    checkGnavWidth();
//  });

  window.addEventListener("resize", function () {
    // ウインドウ横幅が変わっていない場合
    if ( currentWidth == window.innerWidth ) {
      return false;
    }
    checkGnavWidth();
    currentWidth = window.innerWidth; // 横幅を更新

  });

  // -- toggle action
  $('.c-toggleBtn').on('click', function () {
    $(this).toggleClass('is-toggleActive');
    $('html').toggleClass('open');
    $('.l-sitemap').toggleClass('is-open');

    ww = window.innerWidth;
    if(ww>breakpoint) {
      if($('.l-sitemap').hasClass('is-open')){
        console.log('aa');
        $("[data-js='dropDown']").addClass('isDropdownOpen');
      }else {
        $("[data-js='dropDown']").removeClass('isDropdownOpen');
      }
    }else{
      $("[data-js='dropDown']").removeClass('isDropdownOpen');
    }

    //スマホでスクロールした際に上下のバーが出てきて、高さが変わるのに対応する。
    //if( ww <= breakpoint ) {
    //  var windowInnerHeight = window.innerHeight;
      //var headerHeight = 60; //variable.scssの「$headerHeight-sp」と同じ値に。
    //  $('.l-navigation__inner').css('height', windowInnerHeight - headerHeight);
    //}

  });


  function checkGnavWidth () {
    ww = window.innerWidth;
    $("[data-js='dropDown']").off();
    if( ww > breakpoint ) {
      //$('#gnavi').css('display','block');
      //$('.l-navigation__inner').css('height', 'auto');
    } else {
      //$('#gnavi').css('display','none');
      $('#toggle').removeClass('is-toggleActive');
      //$('html').removeClass('open');
    }
    globalNavInit();
  }

  //// --- リサイズ時の初期化
  //function globalNavInit () {
  //  let filter = $(".l-filter");

  //  $("[data-js='dropDown']").on('click', function(){
  //    $(this).toggleClass('is-opened');
  //    if (filter.hasClass('is-active')) {
  //      filter.removeClass('is-active');
  //    } else {
  //      filter.addClass('is-active');
  //    }
  //  });

  //  //黒背景を押しても閉じるように
  //  filter.on('click', function(){
  //    $("[data-js='dropDown']").removeClass('is-opened');
  //    filter.removeClass('is-active');
  //  });
  //}
  // --- リサイズ時の初期化
  function globalNavInit () {
    if( ww >= breakpoint ) {

      //PC挙動
      $(".has-dropdown--gnavi").on('click', function(e){
        e.preventDefault();
        if ($(this).hasClass('isDropdownOpen')) {
          $(this).removeClass('isDropdownOpen');
        } else {
          $(this).addClass('isDropdownOpen');
        }
      });
    } else {
      //ハンバーガーメニュー挙動
      $("[data-js='dropDown'] > a").on('click', function(e){
        e.preventDefault();
        if ($(this).parent("[data-js='dropDown']").hasClass('isDropdownOpen')) {
          $(this).parent("[data-js='dropDown']").removeClass('isDropdownOpen');
        } else {
          $(this).parent("[data-js='dropDown']").addClass('isDropdownOpen');
        }
      });
    }
  }

}
